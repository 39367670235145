//console.log('on and for - File to be edited, rendered into web/scripts/ ...  ');

$(document).ready(function() {

    $(function() {

            //  BASE URL
            var l = window.location;
            var baseurl = l.protocol + "//" + l.host + "/" + l.pathname.split('/')[1]+ "/";
            if (l.host=="localhost") {
                var baseurl = "http://onandfor.test/";
             } else {
                var baseurl = "https://onandfor.eu/"
            }
        
            $('.js-move-to-top').click(function(e) {
                $("html, body").animate({ scrollTop: 0 }, "slow");
            }); 

           /*  $('.js-open-newsletter-layover').click(function(e) {
                e.preventDefault();
                $("html, body").animate({ scrollTop: 0 }, "slow");
                $(".newsletter-layover").show();
            }); 
            $('.js-close-newsletter-layover').click(function(e) {
                $(".newsletter-layover").hide();
            });  */
            
            // ===============================================
            // EVENTS  ====================================
            // ===============================================
            $('.js-hover-effect').hover(function(e) {
              
                // base
                var containerPos = $('#js-container-projects').offset().top;
                var documentHeight = $(document).height(); 
                selectedEventId = $(this).attr("data-hover-id");
                
                // hide all
                $('.js-image-effect').hide();

                // pos calc
                var elParentPos = $(this).offset().top;
                var elPos =  elParentPos - containerPos - 11;
                var imageHeight =  $('*[data-image-id="'+selectedEventId+'"]').height();
                // console.log('containerPos: '+ containerPos +'  elPos: '+elPos + ' imageHeight:'+  imageHeight);
                
                if (containerPos + elPos + imageHeight > documentHeight) {
                    elPos = elPos - 300;
                   // console.log('out of screen');
                }
                // show
                selectedEventId = $(this).attr("data-hover-id");
                $('*[data-image-id="'+selectedEventId+'"]').css({'top' : elPos + 'px'}).show();

        
                
            });

            $('.js-hover-effect').click(function(e) {
                link = $(this).attr("data-click-link");
                window.location.href = link;
            });

             // ===============================================
            // PROGRAMME  ====================================
            // ===============================================



              // ===============================================
            // PARTICIPANTS  ====================================
            // ===============================================


            $('.js-participants-type-filter').click(function(e) {

                selectedCat = $(this).attr("data-nav-type-id");
                $('.cat-links div').removeClass('active');
                $(this).parent().addClass('active');

                $('.js-org-content').show();                    
                $('.js-type-content').show();
                $('.js-country-content').hide();
                
                if (selectedCat !== 'all') {
                   // $('.item-list').fadeOut( "slow" );
                   $('.js-type-content').hide();

                    // artists
                    if (selectedCat == '5') {
                     $('.js-country-content').hide();    
                     $('.js-org-content').hide();    
                    } 
                    // organisations
                   if (selectedCat == '6') {
                     $('.js-org-content').hide();    
                     $('.js-country-content').show();                    
                   } 
                   // curator & programmer
                   if (selectedCat == '7') {   
                       console.log('curator');                 
                    $('.js-country-content').hide();                    
                   } 
                   // Producers & Distributors
                   if (selectedCat == '60') {                    
                        $('.js-org-content').show();                    
                        $('.js-country-content').show();
                    } 
                   // Collectors and Funds
                   if (selectedCat == '213') {                    
                        $('.js-org-content').show();                    
                        $('.js-country-content').show();
                    } 
                   $('.participant').hide();

                   // show select participants
                   
                   //  $('*[data-attr-participants-type-id="'+selectedCat+'"]').fadeIn( "slow" );
                    $('.participant').each(function(index, value) {
                       show = false;
                       types =  JSON.parse($(this).attr('data-attr-participants-type-ids'));
                       $.each(types, function( index, value ) {
                            if (value == selectedCat) { show = true; }
                        });
                        if (show == true) {
                            $(this).fadeIn( "slow" );
                        } 

                    });

                   
                } else {
                    $('.participant').fadeIn('slow');
                }
                

            });




    });

});
